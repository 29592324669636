
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





































.flexible-conditions {
  .flexible .flexible-highlight-duo-outer + & {
    margin-top: $spacing * 2;
    margin-bottom: $spacing * 2;
  }
}

.flexible-conditions__inner {
  position: relative;
  padding-left: 0.6em;
  border-top: $c-light-grey 1px solid;
  border-bottom: $c-light-grey 1px solid;

  .flexible-conditions:last-child & {
    padding-top: $spacing * 3;
    padding-bottom: 0;
    border-bottom: 0;
  }

  .flexible-highlight-duo-outer + .flexible-conditions & {
    padding-top: 0;
    border: 0;
  }

  &::before {
    position: absolute;
    left: 0;
    content: '*';
  }

  @include mq(l) {
    .flexible-conditions:last-child & {
      padding-top: $spacing * 4;
    }

    .flexible-highlight-duo-outer + .flexible-conditions & {
      padding-top: 0;
    }
  }
}
